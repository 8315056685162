import { useIsAuthenticated } from '@azure/msal-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../apiClient/useApiClient';
import { useShowOutOfDate } from '../useShowOutOfDate';
import { WebSocketMessage } from './useWebSocket';

type Props = {
  children: any;
};

type ContextProps = {
  webSocket: WebSocket | null;
  latestWsMessage?: WebSocketMessage;
  initialize: () => void;
  reconnectWebSocket: () => Promise<void>;
  sendMessage: (message: string) => void;
} | null;

export const WebSocketContext = React.createContext<ContextProps>(null);

export function WebSocketProvider({ children }: Props) {
  const webSocket = useRef<WebSocket | null>(null);
  const [latestWsMessage, setLatestWsMessage] = useState<WebSocketMessage>();
  const { connectWebSocket } = useApiClient();
  const intl = useIntl();
  const { showOutOfDate, clearOutOfDate } = useShowOutOfDate();
  const isLoggedIn = useIsAuthenticated();

  const webSocketMessageEvent = useCallback(
    (webSocket: WebSocket) => {
      webSocket.onmessage = (message: MessageEvent) => {
        const parsedMessage = JSON.parse(message.data);
        if (parsedMessage.subject === 'serverListChange') {
          showOutOfDate({ description: intl.formatMessage({ id: 'useshowoutofdate_refresh' }) });
        }
        if (parsedMessage.subject === 'mqttDown') {
          showOutOfDate({
            description: intl.formatMessage({ id: 'useshowoutofdate_mqtt_down' }),
          });
        }
        setLatestWsMessage(parsedMessage);
      };
    },
    [showOutOfDate, intl, setLatestWsMessage]
  );

  const initializeWebSocket = useCallback(() => {
    if (!connectWebSocket) return;
    if (webSocket.current) return;

    webSocket.current = connectWebSocket();
    webSocketMessageEvent(webSocket.current);

    webSocket.current.onclose = (event: CloseEvent) => {
      if (!event.wasClean) {
        showOutOfDate({
          description: intl.formatMessage({ id: 'useshowoutofdate_websocket_error' }),
        });
      }
    };

    webSocket.current.onerror = () => {
      showOutOfDate({
        description: intl.formatMessage({ id: 'useshowoutofdate_websocket_error' }),
      });
    };
  }, [connectWebSocket, intl, showOutOfDate, webSocketMessageEvent]);

  const reconnectWebSocket = useCallback(async () => {
    if (webSocket.current?.readyState === WebSocket.OPEN) {
      return;
    }

    if (!connectWebSocket) return;
    webSocket.current = connectWebSocket();
    webSocketMessageEvent(webSocket.current);

    const openWebSocketPromise = new Promise((resolve) => {
      webSocket.current?.addEventListener('open', resolve, { once: true });
    });

    await openWebSocketPromise;
    initializeWebSocket();
    clearOutOfDate();
  }, [connectWebSocket, initializeWebSocket, clearOutOfDate, webSocketMessageEvent]);

  const sendMessage = useCallback((message: string) => {
    if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
      webSocket.current.send(message);
    } else {
      const handleOpen = () => {
        webSocket.current?.send(message);
        webSocket.current?.removeEventListener('open', handleOpen);
      };
      webSocket.current?.addEventListener('open', handleOpen);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      initializeWebSocket();
    }
  }, [isLoggedIn, initializeWebSocket]);

  return (
    <WebSocketContext.Provider
      value={{
        webSocket: webSocket.current,
        latestWsMessage,
        initialize: initializeWebSocket,
        reconnectWebSocket,
        sendMessage,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}
