import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useApiClient } from '../../apiClient/useApiClient';
import {
  CompatibilityCodeEnum,
  Hardware,
  HardwareProxyParametersDefinitionView,
  ProxyManager,
} from '../../apiClient/types';
import { useShowError } from '../../useShowError';

export function useLoadProxyParametersDefinition(
  hardware: Hardware,
  proxyManager: ProxyManager | undefined
) {
  const [proxyParametersDefinition, setProxyParametersDefinition] =
    useState<HardwareProxyParametersDefinitionView>();
  const { getProxyParametersDefinition } = useApiClient();
  const showError = useShowError();
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);

  const loadProxyParametersDefinition = useCallback(async () => {
    if (!proxyManager || proxyManager.compatibilityCode !== CompatibilityCodeEnum.OK) return;
    setLoading(true);

    try {
      setProxyParametersDefinition(
        await getProxyParametersDefinition({
          proxyManager: proxyManager.identifier,
          hardwareLocation: hardware.location,
        })
      );
    } catch (error) {
      showError(error, {
        description: intl.formatMessage({ id: 'connectionmodal_proxyparameters_error' }),
      });
    }
    setLoading(false);
  }, [hardware, intl, proxyManager, getProxyParametersDefinition, showError]);
  return { loadProxyParametersDefinition, isLoading, proxyParametersDefinition };
}
