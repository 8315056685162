import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Text } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UnauthenticatedLanguageLocaleSelector } from '../../i18n/UnauthenticatedLanguageLocaleSelector';
import { PrimaryNavLink } from '../PrimaryNavLink';
import { LoggedInUserMenu } from './LoggedInUserMenu';
import { MenuDrawer } from './MenuDrawer';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export function DesktopContent() {
  return (
    <AuthenticatedTemplate>
      <PrimaryNavLink to="/dashboard">
        <FormattedMessage id="header_dashboard" />
      </PrimaryNavLink>
      <PrimaryNavLink to="/hardware">
        <FormattedMessage id="header_hardware" />
      </PrimaryNavLink>
      <PrimaryNavLink to="/queues">
        <FormattedMessage id="queue_heading" />
      </PrimaryNavLink>
      <PrimaryNavLink to="/download">
        <FormattedMessage id="header_downloads" />
      </PrimaryNavLink>
    </AuthenticatedTemplate>
  );
}

export function DesktopActions() {
  return (
    <>
      <AuthenticatedTemplate>
        <LoggedInUserMenu />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <PrimaryNavLink to="/login">
          <FormattedMessage id="header_login" />
        </PrimaryNavLink>
        <UnauthenticatedLanguageLocaleSelector />
      </UnauthenticatedTemplate>
    </>
  );
}

export function MobileLoggedIn({ onOpen, isOpen, onClose }: Props) {
  const intl = useIntl();
  return (
    <>
      <IconButton
        color="white"
        _hover={{ bg: 'whiteAlpha.400' }}
        isRound
        variant="ghost"
        aria-label={intl.formatMessage({ id: 'header_navigation' })}
        onClick={onOpen}
        size="sm"
        icon={<Icon as={HamburgerIcon} boxSize={6} />}
      />
      <MenuDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export function MobileLoggedOut() {
  return (
    <HStack spacing={2}>
      <UnauthenticatedTemplate>
        <PrimaryNavLink to="/login">
          <Text fontSize="sm">
            <FormattedMessage id="header_login_mobile" />
          </Text>
        </PrimaryNavLink>
      </UnauthenticatedTemplate>
      <UnauthenticatedLanguageLocaleSelector />
    </HStack>
  );
}
