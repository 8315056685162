import { useContext } from 'react';
import { LOCALES } from './locales';
import { Select } from '@chakra-ui/react';
import { LocaleContext } from './LocaleContext';
import { useIsMobile } from '../useIsMobile';

export function UnauthenticatedLanguageLocaleSelector() {
  const languageLocales = [
    { name: 'English UK', code: LOCALES.ENGLISH_UK },
    { name: 'English USA', code: LOCALES.ENGLISH_US },
    { name: 'Suomi', code: LOCALES.FINNISH },
  ];

  const { locale, onChangeLocale } = useContext(LocaleContext);
  const isMobile = useIsMobile();

  return (
    <Select
      variant="outline"
      color="white"
      borderColor="white"
      focusBorderColor="white"
      onChange={(event) => onChangeLocale(event.target.value)}
      value={locale}
      size={isMobile ? 'xs' : 'sm'}
      iconSize="lg"
      minWidth={isMobile ? '110px' : '120px'}
      maxWidth={isMobile ? '110px' : '150px'}
      borderRadius="md"
      _hover={{ cursor: 'pointer' }}
    >
      {languageLocales.map(({ name, code }) => (
        <option key={code} value={code} className="darkGrayText">
          {name}
        </option>
      ))}
    </Select>
  );
}
